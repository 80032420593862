/* eslint-disable no-useless-escape */

import React from 'react'
import { Box, Text, Image } from 'rebass'
import styled from 'styled-components'
import VideoBackground from '../components/video-background'
/*import { window } from 'browser-monads'*/
import { isMobile } from "react-device-detect";
import Fade from 'react-reveal/Fade'

const HeaderInner = styled.div`
  position: relative;
  width: 100vw;
  min-height: 60vh;
  overflow: hidden;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    height: 100vh;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    height: 150vh;
  }
`

const HeaderBackground = styled.div`
  position: fixed;
  background-color: #3d00ff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  min-height: 100%;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const HeaderContent = styled(Box)`
  position: relative;
  padding: 12rem 2.6rem 6.4rem;
  
  .AboutHeader--difference & {
    mix-blend-mode: difference;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 20rem 4.8rem 8rem;
  }
`
const HeaderTerm = styled.div`
  position: relative;
  display: block;
  width: 100%;
  
  & + * {
    margin-top: 2rem;
  }
  
  h1 {
    color: #fff;
    position: relative;
    display: inline-block;
    font-family: ${props => props.theme.fonts.variable};
    font-weight: 360;
    font-size: ${props => props.theme.fontSizes[6]}px;
    line-height: 1.04;
    margin: 0;
  }

  .AboutHeader--dark & {
    h1 {
      color: ${props => props.theme.colors.black};  
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    & + * {
      margin-top: 0;
    }
    
    h1 {
      font-size: 10vw;
    }

    &:nth-of-type(1) {
      left: -5vw;
    }

    &:nth-of-type(2) {
      left: 50%;
      transform: translateX(-33.3334%);
    }

    &:nth-of-type(3) {
      left: 50%;
      transform: translateX(-20%);
    }

    &:nth-of-type(4) {
      left: 50%;
      transform: translateX(25%);
    }
  }
`


export const Description = styled(Text)`
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 1.04;
  max-width: ${props => props.theme.widths[3]}px;
  margin-top: ${props => props.theme.space[3]}px;

  .ProjectHeader--dark & {
    color: ${props => props.theme.colors.black};
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[7]}px;
    font-weight: 300;
  }
`

const AboutHeader = ({ about, variant }) => {
  const background = ((about.hero_video || about.hero_vimeo_video) && !isMobile) ?
    <VideoBackground
      poster={about.hero_image && about.hero_image.url}
      source={about.hero_video.url}
      vimeo={about.hero_vimeo_video}
    />
    :
    about.hero_image ?
      <Image
        src={about.hero_image.url}
        alt={about.hero_image.alt}
      />
      : null

  return (
    <HeaderInner className={variant ? `AboutHeader--${variant}` : ''}>
      <HeaderBackground>
        {background}
      </HeaderBackground>

      <HeaderContent>
        <div>
          <HeaderTerm>
            <Fade left distance="120px" delay="10ms">
              <h1>Creative</h1>
            </Fade>
          </HeaderTerm>

          <HeaderTerm>
            <Fade right distance="70px" delay="5ms">
              <h1>Digital</h1>
            </Fade>
          </HeaderTerm>
          <HeaderTerm>
            <Fade left distance="80px" delay="20ms">
              <h1>Experience</h1>
            </Fade>
          </HeaderTerm>
          <HeaderTerm>
            <Fade right distance="60px" delay="25ms">
              <h1>Design</h1>
            </Fade>
          </HeaderTerm>
        </div>
      </HeaderContent>
    </HeaderInner>
  )
}

export default AboutHeader
