/* eslint-disable no-useless-escape */

import React from 'react'
import styled from 'styled-components'
import headingClients from '../pages/heading-clients-desktop.svg'
import headingClientsAll from '../pages/heading-clients-all-desktop.svg'
import RichText from './rich-text'

/*import { window } from 'browser-monads'*/

const AboutClientsWrapper = styled.div`
  position: relative;
`;

const AboutClientsInner = styled.div`
  margin: 0 auto;
  max-width: 132rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;

    &.AboutClientsInner--select {
      align-items: center;
      border-bottom: 0.4rem solid #181818;
      padding-bottom: 11.4rem;
    }
  }
`;

const AboutClientsContent = styled.div`
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex: 1 1 100%;
    margin-right: 14.4rem;
  }
`;

const AboutClientsHeading = styled.div`
  display: none;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: block;
    flex: 0 0 5.6rem;
  }
`;

const AboutClientsSelect = styled.div`
  background-color: #CFFF00;
  padding: 7.2rem 2.6rem 8.8rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 11.4rem 4.8rem 17.6rem;
  }
`;

const AboutClientsSelectList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4rem;
  column-gap: 4rem;
  
  img {
    display: block;
    width: 100%;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 10vw;
    column-gap: 8vw;
    align-items: center;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    row-gap: 12.6rem;
    column-gap: 15rem;
  }
`;

const AboutClientsAll = styled.div`
  background-color: ${props => props.theme.colors.darkGrey};
  padding: 4.8rem 2.6rem 8.8rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 11.4rem 4.8rem 17.6rem;
  }
`;

const AboutClientsAllIntro = styled.div`
  font-family: ${props => props.theme.fonts.variable};
  color: #CFFF00;
  line-height: 1.3;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 4rem;
  max-width: 60rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-bottom: 10.4rem;
  }
`;

const AboutClientsAllList = styled.div`
  header {
    display: block;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
  }
  
  div {
    & + * {
      margin-top: 5.6rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: block;
    font-size: 1.6rem;
    font-weight: 520;
    line-height: 0.9;
    padding: 0;
    margin: 0;

    & + * {
      margin-top: 0.8rem;
    }
  }

  a {
    color: #E1DEDE;
    text-decoration: none;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    columns: 2;
    column-gap: 5.6rem;

    li {
      & + * {
        margin-top: 1.2rem;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    columns: 4;
  }
`;

const AboutClients = ({ selectClients, allClientsContent, allClients }) => {
  return (
    <AboutClientsWrapper>
      {selectClients && (
        <AboutClientsSelect>
          <AboutClientsInner className="AboutClientsInner--select">
            <AboutClientsContent>
              <AboutClientsSelectList>
                {selectClients.map((client,i) => (
                  <span key={`select-client-${i}`}>
                    <a href={client.url} target="_blank" rel="noreferrer">
                      <img src={client.logo.url} alt={client.logo.alt}/>
                    </a>
                  </span>
                ))}
              </AboutClientsSelectList>
            </AboutClientsContent>

            <AboutClientsHeading>
              <img src={headingClients} alt="Select Clients"/>
            </AboutClientsHeading>
          </AboutClientsInner>
        </AboutClientsSelect>
      )}

      {allClients && (
        <AboutClientsAll>
          <AboutClientsInner className="AboutClientsInner--all">
            <AboutClientsContent>
              <AboutClientsAllIntro>
                <RichText render={allClientsContent}/>
              </AboutClientsAllIntro>

              <AboutClientsAllList>
                {allClients.map((section,i) => (
                  <div key={`client-section-${i}`}>
                    <header style={{color: section.primary.heading_color}}>{section.primary.title}</header>
                    <ul>
                      {section.items.map((client,i) => (
                        <li key={`client-${i}`}>
                          <a href={client.url} target="_blank" rel="noreferrer">{client.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </AboutClientsAllList>
            </AboutClientsContent>

            <AboutClientsHeading>
              <img src={headingClientsAll} alt="And many more"/>
            </AboutClientsHeading>
          </AboutClientsInner>
        </AboutClientsAll>
      )}
    </AboutClientsWrapper>
  )
}

export default AboutClients
