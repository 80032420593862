import React from 'react'
import styled from 'styled-components'

const ServicesInner = styled.div`
  position: relative;
  background-color: #fff;
  padding: 7.2rem 0 6.4rem;
  
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #fff;
  }
  
  &::before {
    right: 100%;
    width: 8rem;
  }
  
  &::after {
    left: 100%;
    width: 50vw;
  }
`;

const ServicesList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  
  li {
    list-style: none;
    display: flex;
    align-items: center;
    
    & + * {
      margin-top: 2.4rem;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 3.2rem;
    margin: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    h3 {
      font-size: 5.6rem;
    }
  }
`;

const ServiceIcon = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  margin-right: 3.2rem;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    width: 6.4rem;
    height: 6.4rem;
  }
`;

const Services = ({services}) => {

  return (
    <ServicesInner>
      <ServicesList>
        {services.map((service,i) => (
          <li key={`service-${i}`}>
            <ServiceIcon>
              {service.icon && <img src={service.icon.url} alt={service.icon.alt}/>}
            </ServiceIcon>

            <h3>{service.name}</h3>
          </li>
        ))}
      </ServicesList>
    </ServicesInner>
  )
}

export default Services
